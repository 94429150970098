/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
.button {
    &__helper-text {
        font-size: $font-sm;
        margin-top: $size-xs;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.MuiButtonBase-root {
    &.basic-btn {
        svg {
           height: $svg-height-md; 
           width: auto;
           stroke: $color-primary;
        }
        &.secondary {
            color: $color-secondary;
            background-color: transparent;
            svg {
                stroke: $color-secondary;
            }
        }
    }

    &.rectangular-filled-btn {
        text-transform: none;
        padding: $size-md;
        border-radius: $basic-btn-border-radius;

        .MuiButton-endIcon {
            margin-left: $size-sm;
        }
        .MuiButton-startIcon {
            margin-right: $size-sm;
        }
        svg {
            stroke: $color-white;
            width: $svg-height-md;
        }
    }

    &.rectangular-stroked-btn {
        text-transform: none;
        padding: $size-sm;
        border-radius: $basic-btn-border-radius;

        svg {
            margin-right: $size-sm;
            width: $svg-height-md;
        }

        &.primary {
            color: $color-primary;
            border: $primary-border;
            background-color: transparent;

            svg {
                stroke: $color-primary;
            }
        }
    }

    &.custom-btn, &.custom-btn:hover{
        cursor: pointer;
        border: none;
        font-weight: 500;
        font-family: $font-primary;
        color: $color-white;
        border-radius: $brand-btn-border-radius;
        padding: $brand-btn-padding;
        gap: $spacing-sm;
        background-color: $color-primary;
        margin-top: $size-xl;
        margin-bottom: $size-lg;
        line-height: 1;
        text-transform: none;

        svg {
            height: $svg-base-height;
            width: auto;
            stroke: inherit;
        }

        &.small-btn {
            padding: $small-brand-btn-padding;
        }
        
        &.shorter-btn {
            padding: $brand-btn-shorter-padding;
        }
        
        &.slim {
            padding: $brand-btn-slim-padding;
        }
    }

    &.circle-btn {
        border-radius: 50%;
        min-width: $add-btn-size;
        min-height: $add-btn-size;
        display: flex;
        align-items: center;
        justify-content: center;
        &.secondary {
            svg {
                stroke: $color-secondary;
            }
        }
        &.primary {
            svg {
                stroke: $color-white;
            }
        }
    }

    &.icon-btn, &.icon-btn:hover {
        padding: $icon-btn-padding;
        width: $icon-btn-size;
        height: $icon-btn-size;
        min-width: auto;

        svg {
            height: auto;
            width: auto;
            stroke: $color-white;
        }
        &.large {
            width: $icon-btn-large;
            height: $icon-btn-large;
            padding: $spacing-xs;
            svg {
                height: 100%;
                width: 100%;
            }
        }
        &.secondary {
            svg {
                stroke: $color-secondary;
            }
        }
        &.basic-border-radius {
            border-radius: $basic-btn-border-radius;
        }

    }

    &.yellow, &.custom-btn.yellow {
        background-color: $color-yellow-todo-200;
        color: $color-secondary;
        stroke: $color-secondary;
    
        &:hover, &:focus {
            background-color: $color-yellow-todo;
            color: $color-secondary;
        }
    }

    &.primary {
        background-color: $color-primary;
        color: $color-white;
        stroke: $color-white;

        &:hover, &:focus {
            background-color: $color-brand-700;
            color: $color-white;
        }
    }
    
    &.secondary, &.secondary:hover {
        background-color: $color-grey-neutral-100;
        color: $color-secondary;
        stroke: $color-secondary;
        &:hover, &:focus {
            background-color: $color-grey-neutral-200;
            color: $color-secondary;
        }
        svg {
            stroke: $color-secondary;
        }
    }

    &.danger{
        background-color: $color-red-700;
        color: $color-white;
        stroke: $color-white;
    
        &:hover, &:focus {
            background-color: $color-red-600;
        }
    }

    &.Mui-disabled {
        opacity: 0.6;

        &.primary, &.danger {
            color: $color-white;

        }

        &.secondary, &.yellow {
            color: $color-secondary;
        }

        svg {
           opacity: 0.6;
        }
    }

    &.circular-ghost-btn {
        display: flex;
        align-items: center;
        width: $back-btn-size;
        height: $back-btn-size;
        min-width: auto;
        border-radius: $border-circle;

        svg {
            stroke: $color-secondary;
        }
    }

}

.MuiFab-root{
    svg {
        stroke: $color-white;
    }
}

.curved-button-group.MuiButtonGroup-root {
    box-shadow: none;
    width: 100%;

    button {
        width: 50%;
        text-transform: none;
        background-color:  transparent;
        border: 1.5px solid $color-primary;
        color: rgba($color-white, 0.75);

        &:first-child {
            border-radius: $brand-btn-first-border-radius;
            border-right: 0;
          
        }

        &:last-child {
            border-radius: $brand-btn-last-border-radius;
            border-left: 0;
        }

        &.active {
            background-color: $color-primary;
            color: $color-white;
        }
    }

    &.icon-btn, &.icon-btn:hover {
        padding: $size-xxs;
        min-width: $icon-btn-size;
        min-height: $icon-btn-size;

        svg {
            height: auto;
            width: auto;
            stroke: $color-white;
        }

    }

    &.primary, &.primary:hover {
        background-color: $color-primary;
        color: $color-white;
    }

    &.secondary, &.secondary:hover {
        background-color: $color-grey-neutral-100;
        color: $color-secondary;
    }

    &:disabled {
        opacity: 0.6;
    }

    &.danger, &.danger:hover {
        background-color: $color-red-700;
        color: $color-white;
    }
}
