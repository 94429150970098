/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-screen--view {
    width: 100%;
    
    &__header {
        padding: $size-sm $size-sm 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $size-xs;
        
        &__actions {
            width: 100%;
            display: flex;
            justify-content: space-between;

            button {
                svg {
                    margin-right: $size-xs;
                    stroke: $color-primary;
                    fill: none;
                }
            }
            .button-with-menu-list {
                button{
                    svg {
                        margin: 0;
                        stroke: none;
                        fill: $color-secondary
                    }
                }
            }
            &__main {
                display: flex;
                gap: $size-xxs;
                align-items: center;
            }
        }
    }
    &__general-info {
        padding: $default-page-padding;
        z-index: 1;


        &__id {
            margin-bottom: $size-xl;
            font-size: $font-sm;
        }

        &__type {
            font-size: $font-xl;
            margin-bottom: $size-xxs;
        }

        &__title{
            margin-bottom: $size-xl;
        }

        &__value {
            font-size: $currency-size;
            color: $color-primary;
        }

        &__comission {
            margin-top: $size-xxs;
        }

        &__description {
            margin: $size-xl 0 $size-lg;
        }

        &__state {
            margin: $size-xl 0 $size-xs;

            .progress-stepper__wrapper {
                margin-bottom: 0;
            }
        }
        &__action-buttons {
            margin-top: $size-lg;

            .MuiButtonBase-root.custom-btn, 
            .MuiButtonBase-root.custom-btn:hover {
                margin-top: $size-sm;
                margin-bottom: $size-sm;
                padding: 1.3rem $size-xl;
                align-items: flex-start;
            }
        }
    }

    &__section-elements {
        padding: $default-page-padding;

        &__participants, 
        &__missing-documents, 
        &__uploaded-documents, 
        &__contracts {
            padding-bottom: $size-xl;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: $size-xl;

                &__title {
                    font-size: $font-xl;
                    margin-right: $size-sm;

                    .count {
                        margin-left: $size-xxs;
                        color: $color-grey-neutral-300;
                    }
                }
            }

            .upload-document-card-list,.document-card-list, .participant-card-list {
                margin-bottom: $size-md;

                >a { 
                    text-decoration: none;
                }
            }

            .upload-document-card-list {
                .upload-document-card__info__icon-wrap {
                    svg {
                        stroke: $color-secondary;
                    }
                }
            }
            .contract-card {
                box-shadow: $workflow-button-shadow;
            }
        }

        &__participants, 
        &__missing-documents, 
        &__uploaded-documents {
            border-bottom: $base-border;
            margin-bottom: $size-xl;
        }
    }

    &__modal-text {
        margin-bottom: $size-lg;
    }

    &__delete-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .MuiButtonBase-root.primary, 
        .MuiButtonBase-root.primary:hover, 
        .MuiButtonBase-root.secondary, 
        .MuiButtonBase-root.secondary:hover {
            margin-bottom: 0;
        }
    } 
}

.workflow-participant-manage-buttons {
    flex-direction: column;
}

@media only screen and (min-width: $breakpoint-md){
    .workflow-screen--view {
        padding: $default-page-padding-lg;
    
        &__header {
            padding: 0;
        }

        &__sticky-container {
            display: flex;
            height: 100%;
        }

        &__general-info {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            min-width: 25%;
            max-width: 35%;
            height: calc(100% - 40px);
            padding: 0;
            padding-top: $size-lg;
            margin-right: $size-xl;

            & > div {
                border-radius: $modal-border-radius;
                box-shadow: $workflow-button-shadow;
                padding: $size-lg;
            }

            .separator {
                display: none;
            }
        }
    
        &__section-elements {
            padding: 20px;
            width: 100%;
        }
    }
}