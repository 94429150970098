/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.document-card {
    display: inline-grid;
    grid-template-columns: auto max-content;
    width: 100%;
    padding: $size-md;
    position: relative;
    background-color: $color-white;
    cursor: pointer;

    border: $base-border;
    border-radius: $basic-btn-border-radius;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        box-shadow: $workflow-button-shadow;
        z-index: -1;
    }

    &__info {
        width: 100%;
        display: inline-grid;
        grid-template-columns: max-content auto;
        align-items: center;
        gap: $spacing-lg;

        &__icon-wrap {
            height: $svg-height-lg;
            display: flex;
            justify-content: center;
            align-items: center;

            &--AWAITING_APPROVAL {
                svg {
                    fill: $color-grey-neutral-400;
                }
            }

            &--PENDING {
                svg {
                    fill: $color-yellow-todo-500;
                }
            }

            &--APPROVED {
                svg {
                    fill: $color-success;
                }
            }

            &--REJECTED {
                svg {
                    fill:$color-error;
                }
            }

            svg {
                opacity: 1;
                height: 100%;
                width: auto;
            }
        }

        &__details {
            overflow: hidden;

            p,
            h1 {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: $size-xxs 0;
            }

            p {
                font-size: $font-sm;
                line-height: $font-sm;
            }

            h1 {
                font-size: $font-md;
                line-height: $font-md;
            }

            &__status {
                width: max-content;
            }

            &__notes {
                color: $color-grey-neutral-500;
            }

            &__createdBy {
                font-size: $font-xs;
                color: $color-grey-neutral-500;
            }
        }
    }

    .MuiButton-root {
        padding: 0;
        min-width: unset;

        svg {
            fill: $color-grey-neutral-900;
        }
    }
}

.document-card-list {
    border-radius: $basic-btn-border-radius;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
    margin-bottom: $size-md;
}
