/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.text-badge {
    padding: $size-xxs $size-sm;
    border-radius: $badge-border-radius;
    line-height: 1;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    gap: $size-xxs;

    svg {
        width: $svg-base-height;
        height: $svg-base-height;
        fill: $color-white;
        opacity: 0.6;
    }
    &--filled {
        &.text-badge--purple {
            background-color: $color-primary;
            color: $color-white;

        }

        &.text-badge--gray {
            background-color: $color-grey-neutral-100;
            color: $color-secondary;
        }

        &.text-badge--green {
            background-color: $color-success;
            color: $color-white;
        }

        &.text-badge--yellow {
            background-color: $color-yellow-todo-300;
            color: $color-secondary;
            svg {
                fill: $color-secondary;
                opacity: 0.4;
            }
        }
        
        &.text-badge--red {
            background-color: $color-error;
            color: $color-white;
        }
    }

    &--outlined {

        &.text-badge--purple {
            border: $primary-border;
            color: $color-primary;
        }

        &.text-badge--gray {
            border: 1px solid $color-secondary;
            color: $color-secondary;
        }

        &.text-badge--green {
            border: 1px solid $color-success;
            color: $color-success;
        }

        &.text-badge--yellow {
            border: 1px solid $color-warning;
            color: $color-warning;
        }

        &.text-badge--red {
            border: 1px solid $color-error;
            color: $color-error;
        }

    }

    &--text-medium {
        font-size: 1rem;
    }

    &--text-small {
        font-size: $font-sm;
        padding: 4px 10px;
    }

    &--medium-padding {
        padding: 6px 18px;
        border-radius: 18px;
    }
}
