/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
 
 .cart-summary {
    padding: $spacing-lg 0 $spacing-lg $spacing-lg;
    border-left: $base-border;
    position: relative;

    &__loading {
        backdrop-filter: blur(3px);
        border-radius: $basic-btn-border-radius;

        height: 100%;
        width: 100%;
        position: absolute;

        transform: translate(-$spacing-lg, -$spacing-lg);

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 1;
    }

    &__item {
        &:not(:last-of-type) {
            &::after {
                content: "";
                border-bottom: $base-border;
                width: 100%;
                height: 1px;
                display: block;
                margin-top: $spacing-md;
            }
        }

        p {
            color: $color-grey-neutral-500;
        }

        h3,
        p {
            margin: 0;
        }

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: $spacing-md 0;
        }

        .remove-credits {
            color: $color-red-300;
            cursor: pointer;
            margin: 0;
            padding: 0;
            font-size: $font-md;
            line-height: 1;

            &:hover {
                text-decoration: underline;
                background: none;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .cart-summary {
        margin-top: $spacing-lg;
        padding: $spacing-lg;
        border: $base-border;
        border-radius: $basic-btn-border-radius;
    }
}
