/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.contract-screen {
    width: 100%;
    height: 100%;

    &--list {
        padding: $size-lg;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
        }

        .large-add-btn {
            display: none;
        }

        .filters-trigger-btn {
            stroke: $color-secondary;

            svg {
                stroke: inherit;
                width: $svg-height-lg;
                height: auto;
            }
        }
    }

    &__invites-and-applied-filters {
        grid-area: filters;
    }
    &__applied-filters {
        display: flex;
        flex-direction: column;

        &__header {
            button {
                margin-left: $size-sm;
                text-decoration: underline;
            }
        }

        &__list {
            margin: $size-sm 0 $size-xl;

            &__item.MuiButtonBase-root {
                margin-right: $size-sm;
                margin-bottom: $size-xs;
                stroke: $color-secondary;

                .MuiChip-label {
                    display: flex;
                    gap: $size-xxs;
                }

                svg.MuiChip-deleteIcon {
                    width: $svg-height-md;
                    height: auto;
                }
            }
        }
    }

    &__content {
        margin-top: $size-sm;

        &__large-filters {
            display: none;
        }

        &__list {
            .infinite-scroll-wrapper {
                max-height: calc(100vh - 165px);
                overflow-y: auto;
                padding: 0;

                > a {
                    text-decoration: none;
                }
            }

            &__empty-list {
                padding: 0 $size-lg;
            }

            &__wrap {
                margin-bottom: 15px;
                width: 100%;
            }
        }
    }

    &--create {
        display: grid;
        grid-template-rows: auto 60px;
        grid-template-areas:
            "pdfarea"
            "buttons";
        width: 100%;
    }

    .information-page__content-wrap {
        width: 100%;

        .information-page__content {
            padding: 0;
            justify-content: center;

            .icon-wrap {
                height: $svg-height-xl;

                svg {
                    stroke: $color-primary;
                    opacity: 1;
                    height: 100%;
                    width: auto;
                }
            }

            h2 {
                font-size: $font-xxl;
                margin-bottom: $size-md;
            }

            .upload-document-card {
                background: $color-brand-50;
                border-color: $color-primary;
                text-align: left;
                p {
                    color: $color-grey-neutral-700;
                    opacity: 1;
                }
            }

            p {
                font-size: $font-sm;
                opacity: 0.5;
            }
        }
    }

    &__pdf-viewer {
        &__buttons {
            position: absolute;
            right: $spacing-xs;
            bottom: 40%;

            button {
                transition: transform 300ms $cubic-benzier-easing;
            }

            .MuiButtonBase-root.icon-btn,
            .MuiButtonBase-root.icon-btn:hover {
                svg {
                    height: $svg-height-lg;
                }
            }
            .share-component {
                &__btn.MuiButtonBase-root.icon-btn.secondary {
                    background-color: $color-primary;
                    padding: $icon-btn-padding;
                    width: $icon-btn-size;
                    height: $icon-btn-size;
                    border-radius: $input-border-radius;

                    svg {
                        height: auto;
                        width: auto;
                        stroke: $color-white;
                    }
                }
            }
        }
    }

    &__bottom-actions {
        background-color: $color-white;
        height: 60px;
        padding: 9px 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $menu-bar-shadow;
        z-index: 2;
        grid-area: buttons;

        .MuiButtonBase-root.custom-btn {
            margin: 0;
            padding: 15px 40px;
            min-width: 170px;

            svg {
                height: $svg-height-md;
                width: auto;
                stroke: $color-white;
            }

            &:first-child {
                svg {
                    fill: none;
                    stroke: none;
                }

                &.secondary {
                    svg {
                        * {
                            stroke: $color-secondary;
                        }
                    }
                }
            }
        }
    }
}
.pdf-viewer-max-width-content {
    max-width: $breakpoint-md;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.on-boarding-popper.MuiPopper-root {
    max-width: 280px;

    .MuiPaper-root {
        border-radius: 0;
        background-color: $color-secondary;
        color: $color-white;
        margin-right: $size-lg;
        padding: $size-lg;
        text-align: center;

        &:after {
            content: "";
            position: absolute;
            top: 45%;
            right: 1px;
            border-left: 10px solid $color-secondary;
            border-left-color: $color-secondary;
            border-bottom: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid transparent;
        }
    }

    h3 {
        margin-top: $size-xxs;
        margin-bottom: 0px;
        font-size: 1rem;
    }

    p {
        margin-top: $size-xs;
        font-size: $font-sm;
    }
}

.MuiDrawer-root {
    .signers-helper {
        ul {
            padding-left: $size-sm;
            margin: $size-sm 0 $size-lg;

            li {
                text-align: left;
                margin: $size-xs 0;
                font-size: $font-sm;
            }
        }
    }
    .form {
        &__fields {
            .form-field__top {
                justify-content: flex-start;

                label {
                    text-align: center;
                    margin-bottom: $size-xs;
                }
            }

            &__note {
                display: inline-block;
                font-size: $font-sm;
                text-align: center;
                margin: 1.2rem;
            }

            &__signer-line {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: rgba($color-white, 0.08);
                margin-bottom: $size-xs;
                border-bottom: $base-border;
                padding: $size-sm 0;

                &:last-child {
                    border: none;
                }

                svg {
                    stroke: none;
                }

                &__info,
                &__actions {
                    &__icon-wrap {
                        width: 3.571rem;
                        min-width: 3.571rem;
                        height: 3.571rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        fill: $color-secondary;
                        stroke: none;

                        &__typography {
                            margin: $size-sm 0;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        &__name {
                            margin: 0 0 $size-xxs;
                            text-align: left;
                        }

                        &__signed {
                            font-size: $font-sm;
                            color: $color-primary;
                        }

                        &__not-signed {
                            font-size: $font-sm;
                            color: $color-grey-neutral-300;
                        }

                        &__no-placeholder {
                            font-size: $font-sm;
                            color: $color-red-500;
                        }
                        
                    }
                }

                &__info {
                    display: flex;
                    justify-items: flex-start;
                    align-items: inherit;

                    &__icon-wrap {
                        background-color: $color-grey-neutral-100;
                        border-radius: 50%;
                        margin-right: $size-md;
                    }

                    svg {
                        stroke: $color-primary;
                        height: $svg-height-lg;
                        width: auto;
                    }
                }
                &__notExpired {
                    span {
                        font-size: $font-sm;
                        color: $color-yellow-todo-400;
                        margin-left: $size-xxs
                    }
                }
                &__alert {
                    display: flex;
                    align-items: center;
                    margin-left: -$size-xxs;

                    span {
                        font-size: $font-sm;
                        margin-left: $size-xxs
                    }

                    svg {
                        stroke: $color-yellow-todo-400;
                        height: $svg-height-md;
                        width: auto;
                    }
                }

                &__actions {
                    display: flex;
                    justify-items: flex-end;
                    align-items: center;

                    .share-component button, .share-component button:hover {
                        margin-top: 0;
                    }

                    &__icon-wrap {
                        display: flex;
                        justify-content: flex-end;

                        svg {
                            fill: $color-secondary;
                        }
                    }

                    .switch {
                        &__toggle {
                            height: 2.357rem;
                            width: 4rem;
                        }
                    }
                }

                .switch__toggle {
                    margin-right: 0;
                }
            }

            &__state {
                display: flex;
                flex-direction: column;

                p {
                    margin-top: 0;
                    text-align: start;
                    padding-left: $size-md;
                }
            }
        }

        &__form-line {
            display: flex;
            width: 100%;

            &__two-thirds {
                width: calc(67% - 0.5rem);
                margin-right: 0.5rem;
            }

            &__one-third {
                width: 33%;
            }
        }

        &__bottom {
            button,
            button:hover {
                margin-top: 0.714rem;
                margin-bottom: 0;
            }
        }

        #documentNumber {
            letter-spacing: 2px;
        }
        .add-signer-btn-wrapper {
            width: 100%;
            padding: $size-xxs 0 $size-lg;
        }
    }

    .close-button__wrap {
        margin-bottom: 0.357rem;

        > svg {
            stroke: $color-white;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .contract-screen {
        &--list {
            display: grid;
            grid-template-areas:
                "header"
                "filters"
                "content";
            grid-template-rows: auto auto 1fr;
            height: 100%;
        }

        &__header {
            grid-area: header;
            align-items: flex-start;

            .large-add-btn {
                display: block;
            }

            .filters-trigger-btn {
                display: none;
            }
        }

        &__content {
            grid-area: content;
            display: grid;
            grid-template-columns: 300px 1fr;
            gap: $size-lg;
            max-height: 100%;
            overflow: hidden;

            &__large-filters {
                display: block;
                border: $base-border;
                border-radius: $card-border-radius;
                box-shadow: none;
                max-height: 100%;
                overflow: hidden;
            }

            &__list {
                max-height: 100%;
                overflow: auto;
            }
        }
    }

    .filters-drawer {
        display: none;
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .contract-screen {
        &__header {
            .large-add-btn {
                display: block;
            }
        }

        &__content {
            grid-template-columns: 350px 1fr;
        }
    }

    .add-btn-wrap {
        display: none;
    }
}
