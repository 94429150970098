/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.default-layout {
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    grid-template-areas:
        "navbar navbar"
        "pagearea pagearea"
        "footer footer"
        "menubar menubar";
    height: 100dvh;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;

    .nav-bar-wrap {
        grid-area: navbar;
    }

    .menu-bar {
        grid-area: menubar;
    }

    &__content {
        grid-area: pagearea;
        overflow: auto;
        position: relative;
        place-items: center;
        
        .top-notification-alert {
            display: none;
        }
    }

    &__footer {
        grid-area: footer;

        &__btns-wrap {
            background-color: $color-white;
            padding: 9px 3px;
            display: flex;
            justify-content: center;
            border-top: 1px solid $color-grey-neutral-100;

            .MuiButtonBase-root.custom-btn {
                margin: 0;
                padding: 15px 30px;
                max-width: 200px;
                width: 49%;

                .btn-content {
                    display: flex;
                    align-items: center;

                    svg {
                        height: auto;
                        width: 12px;
                    }
                }

                &:first-child {
                    .btn-content {
                        svg {
                            margin-right: 8px;
                            fill: $color-secondary;
                        }
                    }
                }

                &:last-child {
                    .btn-content {
                        svg {
                            margin-left: 8px;
                            fill: $color-white;
                        }
                    }
                }

                &.Mui-disabled {
                    color: inherit;
                    opacity: 0.5;
                }
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    .default-layout {
        grid-template-areas:
            "menubar navbar"
            "menubar pagearea"
            "menubar footer";
        grid-template-columns: auto 1fr;
    }
}
