/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: $font-primary;
    font-size: 14px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: $color-secondary;
}

#root,
.app-router {
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

button {
    cursor: pointer;
}

.field-error {
    font-size: 0.786rem;
    line-height: 0.786rem;
    color: #D32222;
    padding-top: 0.714rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
}

h1 {
    font-size: $font-xl;
    padding: $size-lg 0;
    margin: 0;
}

h2 {
    font-size: $font-lg;
}

input,
select,
textarea {
    font-size: 1rem;
    color: $color-secondary;
}

label.required::after{
    content: ' *'
}

a {
    color: $color-primary;
}

/* FORMS */
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__fields {
        width: 100%;
    }

    &__bottom {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        margin-bottom: 0.429rem;
        align-items: center;
    }

    label {
        display: block;
        color: $color-secondary;
        margin-bottom: $size-sm;
    }

    textarea {
        display: block;
        border: $base-border;
        border-radius: $input-border-radius;
        padding: $input-padding;
        width: 100%;
        height: 100%;
        outline: none;
        background-color: $color-white;
        font-family: $font-primary;
        transition: ease-out 500ms;
        margin-bottom: $size-lg;

        &:hover {
            border-color: $color-grey-neutral-300;
        }

        &:focus {
            outline: $primary-border;
            border: $primary-border;
        }
    }
}

ul {
    list-style: none;
}

li {
    list-style: none;
    margin-bottom: $size-xxs;
}

.grid-2-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-md;
}

.warning-msg {
    color: red;
}

/* OTHER CUSTOM REUSABLE CLASSES */

.full-width {
    width: 100%;
}

.card {
    background-color: $color-white;
    border-radius: 0.857rem;
    font-size: $font-sm;

    border: $base-border;
    box-shadow: none;

    h3 {
        font-size: 1rem;
    }
}

.card-default-inner {
    padding: $size-md $size-xl;
}

.primary-gradient-bg {
    background: rgb(58, 59, 69);
    background: linear-gradient(155deg, rgba(58, 59, 69, 1) 15%, rgba(101, 112, 231, 1) 100%);
    color: $color-white;
}

.light-gradient-bg {
    background: $gradient-purple-to-white;
    color: $color-grey-neutral-900;
}

.information-page {
    height: 100%;
    width: 100%;

    &__content-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $x-padding;
    }

    &__content {
        > svg {
            fill: $color-white;
            opacity: 0.4;
            margin-bottom: 1.786rem;
        }

        h1 {
            font-size: 2.857rem;
            margin: 0 0 1.429rem;
            line-height: 1.2;
        }

        p {
            line-height: 1.6;
        }
    }
}

.bg-secondary {
    background-color: $color-secondary;
}

.u-bg {
    background-image: url(../../images/logo-unlockit.svg);
    background-position: center;
}

.content-center {
    text-align: center;
}

.close-button__wrap {
    text-align: center;

    > svg {
        width: 3.571rem;
        height: 3.571rem;
    }
}

.separator {
    display: inline-block;
    width: 100%;
    border-top: $base-border;
    margin-top: $size-md;
}

// Screen add button on bottom-right corner
.add-btn-wrap {
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 98;
}

.dark-custom-scrollbar {
    &::-webkit-scrollbar {
        width: $custom-scroll-width;
    }

    &::-webkit-scrollbar-track {
        background: $color-secondary;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color-grey-neutral-100, 0.3);
        border-radius: $custom-scroll-border-radius;

        &:hover {
            background: rgba($color-grey-neutral-100, 0.5);
        }
    }
}

/* Material UI  custom styles */
.MuiPaper-root-MuiAlert-root .MuiAlert-icon svg.MuiSvgIcon-root {
    color: $color-secondary;
    fill: $color-secondary;
}
.base-Popper-root.custom-popper {
    >div {
        border-radius: $size-xxs;
        box-shadow: $popper-element-shadow;
        background-color: lighten($color-secondary, 0.2);
        opacity: 0.8;
        font-size: $font-xs;
        color: $color-white;
        padding: $size-xxs $size-xs;
        margin-top: $size-xxs;
    }
}

// Blurs
.canvas-blur {
    canvas {
        backdrop-filter: blur(20px); /* Apply blur effect */
        filter: blur(20px); /* Fallback for older browsers */
    }
}

// Rotation
.rotate-90 {
    transform: rotate(90deg);
}
.rotate--90 {
    transform: rotate(-90deg);
}

.organization-avatar {
    border: 2px solid $color-white;
}

// Toasts
.Toastify__toast-theme--colored {
    &.Toastify__toast--error {
        background: $color-error;
    }
    &.Toastify__toast--success {
        background: $color-success-darker;
    }
}
@media only screen and (min-width: $breakpoint-md) {
    h1 {
        font-size: $font-xxl;
    }
    h2 {
        font-size: $font-xl;
    }
}

@media only screen and (min-width: $breakpoint-xl) {
    html,
    body {
        font-size: 16px;
    }
    h1 {
        font-size: $font-xxxl;
    }
}
