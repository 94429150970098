/**
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/* COLORS */
$color-black: #000000;
$color-white: #ffffff;

$color-brand-50:  #E9E4FA;
$color-brand-100: #D5CFF6;
$color-brand-200: #B7ADF1;
$color-brand-300: #9989EB;
$color-brand-500: #664EE0;
$color-brand-600: #5545D1;
$color-brand-700: #4722AD;
$color-brand-800: #36198A;

$color-grey-neutral-50: #f6f6f6;
$color-grey-neutral-100: #e7e7e7;
$color-grey-neutral-200: #d1d1d1;
$color-grey-neutral-300: #b0b0b0;
$color-grey-neutral-400: #888888;
$color-grey-neutral-500: #747474;
$color-grey-neutral-700: #5d5d5d;
$color-grey-neutral-900: #3a3b45;
$color-grey-neutral-950: #262626;

$color-yellow-todo-50: #FFFEC0;
$color-yellow-todo-100: #F5F57C;
$color-yellow-todo-200: #F2F254;
$color-yellow-todo-300: #f5f542;
$color-yellow-todo-400: #eada23;
$color-yellow-todo-500: #E5BF00;
$color-yellow-todo-600: #CDAD0C;

$color-red-100: #F4A3A3;
$color-red-300: #EB4A4A;
$color-red-500: #D92D2D; 
$color-red-600: #D32222; 
$color-red-700: #B8111A; 

$color-success-darker: #178540; //Toast success background


// Social colors
$whatsapp-color: #25D366;
$telegram-color: #24A1DE;

// Defaults
$color-primary: $color-brand-600;
$color-secondary: $color-grey-neutral-900;
$color-yellow-todo: $color-yellow-todo-300;

// Informative colors
$color-error: $color-red-600;
$color-success: #33a35d;
$color-warning: $color-yellow-todo-400;

// Menu colors
$menu-color: rgba($color-white, 0.5);
$active-menu-color: $color-white;

// Link color
$link-color: $color-brand-600;


/* FONTS */
$font-primary: "Aeonik Fono", sans-serif;

/* Typography */
$font-xs: 0.714rem; // 10px
$font-sm: 0.857rem; // 12px
$font-md: 1rem; // 14px (mobile)
$font-lg: 1.143rem; // 16px
$font-xl: 1.286rem; // 18px
$font-xxl: 1.786rem; // 25px
$font-xxxl: 1.875rem; // 30px (large)

$font-innovators-title: 1.571rem;
$currency-size: 1.571rem; //22px

/* Paddings */
$brand-btn-padding: 1.3rem 4.286rem;
$brand-btn-slim-padding: 1rem 4.286rem;
$brand-btn-shorter-padding: 1.3rem 2.286rem;
$small-brand-btn-padding: 0.643rem 2.857rem;
$icon-btn-padding: 0.857rem;
$navbar-padding-mobile: 0.143rem 1.429rem 0.214rem;
$navbar-padding: 15px 1.429rem 14px; //need fixed height
$navlinks-padding: 0.357rem 0.714rem 0;
$sidebar-padding: 1.429rem 2.143rem 2.143rem;
$input-padding: 1.071rem;
$loading-input-padding: 1.429rem 1.071rem;
$select-padding: 1.071rem 2.7rem 1.071rem 1.071rem;
$modal-padding: 1.429rem;
$menu-bar-mobile-padding: 0.929rem 0.857rem 0.714rem;
$menu-bar-mobile-extra-side-padding: 0.929rem 1.429rem 0.714rem;
$menu-bar-desktop-item-padding: 1rem 3rem 1rem 1.8rem;
$esignatures-layout-padding: 1rem 1.429rem 1.071rem;
$currency-input-padding: 0.893rem 1.071rem;
$isolate-flow-page-padding: 1.071rem 3.214rem 3.214rem;
$isolate-flow-page-padding-md: 1.071rem 2.857rem 1.786rem;
$upload-document-card-padding: 1.071rem 0.571rem 1.071rem 1.071rem;

/* GLOBAL SIZES */
$size-xxxs: 0.143rem; // 2px
$size-xxs: 0.286rem; // 4px
$size-xs: 0.571rem; // 8px
$size-sm: 0.714rem; // 10px
$size-md: 1.071rem; // 15px
$size-lg: 1.429rem; // 20px
$size-xl: 1.786rem; // 25px
$size-xxl: 2.5rem; // 35px
$size-xxxl: 3.214rem; // 45px

$max-height-filter-checkbox: 300px;
$max-height-filter-radios: 240px;

/* Sizing */
$spacing-xxxs: 0.143rem; // 2px
$spacing-xxs: 0.285rem; // 4px
$spacing-xs: 0.571rem; // 8px
$spacing-sm: 0.714rem; // 10px
$spacing-md: 0.857rem; // 12px
$spacing-lg: 1.429rem; // 20px
$spacing-xl: 3.125rem; // 50px - desktop
$spacing-xxxl: 7.143rem; // 100px

$icon-btn-size: 3.429rem;
$icon-btn-md: 3.8rem;
$icon-btn-large: 4rem;
$back-btn-size: 2.857rem;
$svg-height-sm: 13px;
$svg-base-height: 1.071rem; // 15px
$svg-height-md: 1.429rem; // 20px
$svg-height-lg: 1.714rem; // 24px
$svg-height-xl: 2.143rem; // 30px
$svg-height-xxl: 2.5rem; // 35px
$svg-height-xxxl: 3.1rem; // 43px
$pdf-viewer-svg-height: 17px;
$logo-svg-height: 2.429rem;
$error-screen-svg-padding: 40px;
$add-btn-size: 40px;
$custom-scroll-width: 0.375rem;
$large-menu-min-width: 280px;
$banner-top-mobile: 54px;
$banner-top-desktop: 85px;
$error-modal-max-width: 300px;
$modal-button-line-button-width: 140px;
$full-height: 100dvh;
$usage-chart-height: 1.5rem;
$background-element-height: 295px;
$avatar-lg: 38px;
$avatar-md: 35px;
$qrcode-size: 220px;
$qrcode-modal-size: 300px;
$invites-height-mobile: 140px;
$invites-height-mobile-bigger: 280px;
$invites-height-desktop: 80px;
$invites-height-desktop-bigger: 160px;
$invites-white-gradient: 40px;
$drawer-size-lateral: 400px;

/* Page configs */
$x-padding: 1.429rem;
$default-page-padding: 1rem 1.429rem;
$default-page-padding-lg: 1.429rem 2.848rem;
$sidebar-padding: 1.429rem 2.143rem 2.143rem;

/* Rectangle Stepper */
$stepper-size: 1.3rem;
$todo-color: #adadad66;

/* Shadows */
$input-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.55);
$menu-bar-shadow: 0px -8px 10px 2px rgba(0, 0, 0, 0.05);
$banner-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
$sidebar-shadow: -8px 0 10px 2px rgba(0, 0, 0, 0.05);
$smart-code-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
$workflow-button-shadow: 0px 7px 20px 0px rgba(100, 100, 111, 0.2);
$card-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
$card-shadow-strong: 0px 8px 30px 0px rgba(100, 100, 111, 0.4);
$isolate-flow-card-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
$white-shadow: 0px -8px 30px 30px rgba(255, 255, 255, 1);
$user-profile-side-bar-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.102);
$popper-element-shadow: 0px 0px 20px 1px rgba(0,0,0, 0.15);

/* Border radius */
$basic-btn-border-radius: 8px;
$card-border-radius: 12px;
$image-border-radius: 16px;
$brand-btn-border-radius: 40px 0px 40px 0px;
$brand-btn-first-border-radius: 40px 0 0 0;
$brand-btn-last-border-radius: 0 0 40rem 0;
$border-circle: 50%;
$input-border-radius: 4px;
$custom-scroll-border-radius: 0.188rem;
$modal-border-radius: 8px;
$badge-border-radius: 1.143rem;

/* Border Sizes */
$border-size-xxxxs: 1px;
$border-size-xxxs: 2px;
$border-size-xxs: 4px;
$border-size-xs: 8px;

/* Borders */
$base-border: $border-size-xxxxs solid $color-grey-neutral-100;
$base-border-2: $border-size-xxxs solid $color-grey-neutral-100;
$base-border-light: $border-size-xxxxs solid $color-grey-neutral-50;
$base-border-3: $border-size-xxxs solid $color-white;
$primary-border: $border-size-xxxxs solid $color-primary;
$primary-border-2: $border-size-xxxs solid $color-primary;
$hr-border: $border-size-xxxxs solid $color-grey-neutral-200;
$menu-mobile-default-border: $border-size-xxs solid transparent;
$menu-desktop-default-border: $border-size-xs solid transparent;
$error-border: $border-size-xxxs solid rgba($color-red-600, 0.4);

/* Transitions */
$cubic-benzier-easing: cubic-bezier(0.785, 0.01, 0.545, 0.995);
$cubic-bezier-loading: cubic-bezier(0.39, 0.14, 0.71, 0.15);
$input-transition: all 0.3s ease-in-out;
$svg-transition: all 0.2s ease;
$disabled-opacity: 0.4;

// CMD Brand
$color-cmd: #4159b0;

/* Media sizes */
$breakpoint-xs: 500px; // e.g: smartphone vertical
$breakpoint-sm: 600px; // e.g: small tablets or horizontal phone
$breakpoint-md: 900px; // e.g: tablets
$breakpoint-lg: 1200px; // e.g: laptops
$breakpoint-xl: 1536px; // e.g: large desktops

/* Gradients */
$gradient-purple-to-white: linear-gradient(180deg, $color-brand-50 0%, rgba(255, 255, 255, 0) 100%);
$gradient-clear-purple: linear-gradient(92.36deg, $color-yellow-todo-100 0%, $color-brand-50 100%);

/* PDF Viewer */
$pdf-placeholder-outside-border: 1px dashed $color-primary;
$pdf-placeholder-spacing-border: 1px dashed rgba($color-secondary, 0.5);
$pdf-placeholder-pending-border-width: 2px;

$pdf-placeholder-spacing: 5px;
$pdf-placeholder-signed-date-padding: 3px 0;
$pdf-placeholder-svg-size: 10px;
$pdf-placeholder-h1-font-size: 5px;

$pdf-placeholder-pending-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
$pdf-placeholder-dragging-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
