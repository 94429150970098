/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.error-screen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $size-xxxl $x-padding;

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $color-brand-100;
        margin-bottom: $size-sm;
        padding: $size-lg;

        svg {
            height: $icon-btn-size;
            width: $icon-btn-size;
            fill: $color-brand-500;
        }
    }

    &__body {
        text-align: center;
        white-space: break-spaces;
        margin-bottom: $size-xxxl;

        &__red-error {
            color: $color-error;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .custom-btn {
            background-color: $color-brand-500;
        }
    }
}

@media only screen and (min-width: $breakpoint-sm) {
    .error-screen {
        &__icon-container {
            padding: $size-xl;
            margin-bottom: $spacing-xl;
        }
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .error-screen {
        &__icon-container {
            padding: $error-screen-svg-padding;
            margin-bottom: $spacing-xxxl;
        }
    }
}
