/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

.pending-invites {
    position: relative;
    margin: $size-sm 0 $size-xl;
   

    &.hasMore {
        &::after {
            content:'';
            height: $invites-white-gradient;
            bottom:0;
            width: 100%;
            position: absolute;
            background: linear-gradient(0deg, #ffffff 0%, transparent 100%); 
            border-radius: 0 0 $basic-btn-border-radius $basic-btn-border-radius;
            border: $base-border;
            border-top: none;
        }
    }
    &__header {
        margin-bottom: $size-md;

        h2 {
            margin: 0 0 $size-xxs;
        }
        p {
            color: $color-grey-neutral-700;
            margin-top: 0;
        }
    }
    &__list {
        border-radius: $basic-btn-border-radius;
        box-shadow: $workflow-button-shadow;
        padding: 0;
        height: $invites-height-mobile;
        overflow: hidden;
        background-color: $color-white;

        &.more-elements-per-scroll {
            height: $invites-height-mobile-bigger;
        }

        .infinite-scroll-wrapper {
            padding: 0;
            max-height: calc(100vh - 165px);
            overflow-y: auto;
        }
        &__item {
            display: flex;
            flex-direction: column;
            gap: $size-md;
            width: 100%;
            padding: $size-md;
            position: relative;
            border: $base-border;
            border-radius: $basic-btn-border-radius;

            &__buttons {
                display: flex;
                justify-content: space-between;
                gap: $size-sm;
                >span {
                    display: flex;
                    gap: $size-md;
                }

                .MuiButtonBase-root {
                    &.custom-btn, &.custom-btn:hover,
                    &.basic-btn {
                        margin: $size-xxs 0;
                    }
                }
            }

            &.load-more-button,&.request-more-button {
                align-items: flex-start;
            }

            &:not(:last-of-type) {
                &::after {
                    content: "";
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom: $base-border;
                }
            }

            // reset bottom radiuses and border-bottom if card is the 1st in a list
            &:first-of-type:not(:only-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: none;
            }

            // reset top radiuses and border-top if card is the last in a list
            &:last-of-type:not(:only-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: none;
            }

            // reset radiuses and top/bottom border if card is the middle of a list
            &:not(:first-of-type):not(:last-of-type) {
                border-radius: 0;
                border-bottom: none;
                border-top: none;
            }
        }
        }
}

@media only screen and (min-width: $breakpoint-md) {
    .pending-invites {
        &__list {
            height: $invites-height-desktop;

            &.more-elements-per-scroll {
                height: $invites-height-desktop-bigger;
            }

            &__item {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: $size-lg;
            }
        }
    }
}
