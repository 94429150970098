/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

// Outer wrapper - here to be reusable
.pdf-viewer-wrap {
    height: 100%;
    position: relative;
    display: block;
    padding: 0 $x-padding;
    overflow-x: scroll;
    grid-area: pdfarea;
}

.pdf-wrapper {
    position: relative;
    height: 100%;

    &__page-list-wrapper {
        height: 100%;
    }
}

.pdf-viewer {
    height: 100%;
}

.pdf-navigation {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba($color-white, 0.1), $color-white);
    padding: 10px;
    gap: 10px;
    color: $color-secondary;

    .MuiStack-root {
        svg {
            width: $pdf-viewer-svg-height;
            stroke: $color-secondary;
        }
    }

    .icon-btn,
    .icon-btn:hover {
        padding: $size-xxs;
        width: $svg-height-xl;
        height: $svg-height-xl;

        svg {
            width: $pdf-viewer-svg-height;
        }
    }

    .slider-container {
        min-width: 100px;
        max-width: 200px;
        height: 25px;

        padding: 0 12px;

        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        background-color: $color-grey-neutral-300;

        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiSlider-root {
        .MuiSlider-track {
            background: none;
            border: none;
        }

        .MuiSlider-rail {
            background: none;
        }

        .MuiSlider-thumb {
            background-color: $color-white;
        }
    }
}

.pdf-page {
    position: relative;

    &__text-layer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        opacity: 0.2;
        line-height: 1;

        & > span {
            color: transparent;
            position: absolute;
            white-space: pre;
            cursor: text;
            transform-origin: 0% 0%;
        }
    }
}

.pdf-placeholder-annotation {
    position: absolute;
    border: $pdf-placeholder-outside-border;
    background: $color-yellow-todo-100;
    padding: $pdf-placeholder-spacing;

    transition: box-shadow 0.2s ease;
    box-sizing: border-box;
    cursor: grab;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    z-index: 10;

    &:active {
        cursor: grabbing;
    }

    h1 {
        font-size: calc(var(--scale-factor) * $pdf-placeholder-h1-font-size);
    }

    &__signer-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $pdf-placeholder-spacing;

        h1 {
            padding: 0;
        }

        svg {
            width: $pdf-placeholder-svg-size;
            height: $pdf-placeholder-svg-size;
        }
    }

    &__signed-date {
        color: $color-primary;
        padding: $pdf-placeholder-spacing;
        border-top: $pdf-placeholder-spacing-border;
        border-bottom: $pdf-placeholder-spacing-border;

        &--awaits-signature {
            color: rgba($color-primary, 0.5);
            padding: $pdf-placeholder-signed-date-padding;
        }
    }

    &--pending {
        border-width: $pdf-placeholder-pending-border-width;
        box-shadow: $pdf-placeholder-pending-shadow;
    }

    &--dragging {
        box-shadow: $pdf-placeholder-dragging-shadow;
    }

    &--disabled {
        cursor: unset;

        &:active {
            cursor: unset;
        }
    }
}
