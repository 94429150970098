/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

.workflow-history-screen {
    width: 100%;
    padding: $size-md;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    "header"
    "page";
    height: 100%;
    overflow: hidden;

    h1 {
        font-size: $font-xl;
    }
    .MuiButton-root{
        padding: 0px;
        min-width: $spacing-xl;
    }
    .MuiStepper-root{
        margin-left: $size-md;
    }

    &__header {
        grid-area: header;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;   

        .filters-trigger-btn {
            color: $color-secondary;
            svg {
                stroke: $color-secondary;
            }
        }
    }

    .spacer {
        flex-grow: 1;
    }

    .step {
        padding: $size-lg $size-md;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
      
    .v-stepper {
        position: relative;
        top: $size-lg;
    }
      
    .step .circle {
        background-color: $color-primary;
        margin-left: $size-xxs;
        border-radius: 100%;
        width: $size-lg;   
        height: $size-lg;
        display: inline-block;
    }
    
    .step .line {
        top: $size-lg;
        left: $spacing-md;
        position: absolute;
        border-left: $base-border-2;
    }

    .content {
        margin-left: $size-xxl;
        display: inline-block;

        .notes-container {
            display: block;
        }
        
        .label {
            font-size: $spacing-md;
            color: $color-grey-neutral-500;
            display: inline; 
            margin-right: $size-xxs; 
            white-space: nowrap; 
        }
        
        .notes-content {
            font-size: $spacing-md;
            color: $color-grey-neutral-500;
            display: inline; 
            word-break: break-word; 
        }
        ul {
            padding-left: 0px;
            margin-top: $size-xxxs;
            
            li {
                font-size: $spacing-md; 
                color: $color-grey-neutral-700; 
                
                &:not(:last-child) {
                    margin-bottom: $size-xs; 
                }
            }
        }
    }
    .back-button {
        margin: $size-md 0;
        margin-left: -$size-xxs;
        padding-left: 0;
      
    }
    .no-padding-ul {
        padding-left: 0;
        list-style-position: inside;
    }
    .custom-step-icon {
        width: $size-md; 
        height: $size-md;
        border-radius: $border-circle;
        background-color: $color-primary;
        margin-left: $size-xxs;
    }

    .MuiTimelineDot-root{
       background: $color-primary;

       svg{
        fill: rgba($color-white, 0.9);
       }
    }

    .MuiTimelineOppositeContent-root{
        flex: 0;
        padding: 0;
        margin-left: -$size-lg;
    }

    .description{
        padding-bottom: $size-sm;
        ul {
            margin-top: $size-sm;
            li {
                list-style-type: circle;
            }
        }
        
    }

    .notes {
        display: block;
        line-break: anywhere;
        max-width: 100%; 

        span {
            margin: 0;
            color: $color-grey-neutral-500;
            font-size: $font-sm;
    }
    }
    
    .timestamp{
        p{
            margin: -$size-md 0 0 0;
            color: $color-grey-neutral-500;
            font-size: $font-sm;
        }
    }
    .creator{
        padding-bottom: $size-lg;
        p{
            margin: 0;
            color: $color-grey-neutral-500;
            font-size: $font-sm;
        }
    }

    &__list {
        grid-area: page;
        .infinite-scroll-wrapper {
            max-height: calc(100vh - 165px);
            overflow-y: auto;
        }
    }
}



